import { Helmet } from 'react-helmet';
import { Stack } from '@mui/material';

function Events() {
  return (
    <>
      <Helmet>
        <title>Thick Bikes - Upcoming Events</title>
      </Helmet>
      <Stack
        alignItems="center"
        sx={{
          pt: ['70%', '54%'],
          pb: [2, 6],
          m: 0,
          px: 0,
          background: 'black',
          backgroundSize: 'cover',
        }}
      >
        <Stack direction="row" spacing={[2, 5, 5]}></Stack>
      </Stack>
      <p>events</p>
      <Stack
        alignItems="center"
        sx={{
          pt: ['70%', '54%'],
          pb: [2, 6],
          m: 0,
          px: 0,
          background: 'black',
          backgroundSize: 'cover',
        }}
      >
        <Stack direction="row" spacing={[2, 5, 5]}></Stack>
      </Stack>
    </>
  );
}
export default Events;
