import { useState } from 'react';
import './thicklogo.css';

export const ThickLogo = () => {
  const [animateHeader, setAnimateHeader] = useState(false);
  return (
    <span
      className={animateHeader ? 'mainLogo hover' : 'mainLogo'}
      onPointerEnter={() => {
        if (animateHeader) return;
        setAnimateHeader(true);
        setTimeout(() => {
          setAnimateHeader(false);
        }, 1000);
      }}
    >
      <svg width="128" height="41" viewBox="0 0 128 41" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_25_155)">
          <path
            className="letter1"
            d="M32.9101 16.9838H28.1398V29.7315H20.4731V16.9838H15.7378V11.8197H32.8996V16.9838H32.9101Z"
          />
          <path
            className="letter2"
            d="M53.2381 29.7315H45.8234V22.6101H42.6257V29.7315H35.4563V11.8197H42.6257V18.7136H45.8234V11.8197H53.2346V29.7315H53.2381Z"
          />
          <path className="letter3" d="M64.5788 11.8197V29.7315H56.6809V11.8197H64.5788Z" />
          <path
            className="letter4"
            d="M85.849 18.6486H78.55V15.467H75.2787V26.0841H78.55V22.6462H85.849V27.2325L82.6968 29.7315H71.2229L67.78 27.2325V14.3439L71.2229 11.8161H82.6688L85.849 14.3439V18.6486V18.6486Z"
          />
          <path
            className="letter5"
            d="M96.1251 18.6414H96.9482L101.547 11.8197H109.546L103.48 20.8659L110.215 29.7315H102.324L96.9447 22.6354H96.1251V29.7315H88.707V11.8197H96.1251V18.6414Z"
          />
          <path
            className="letter6"
            d="M127.416 40.9987H0.390137V0.552551H127.416V40.9987ZM5.23398 35.9898H122.565V5.56137H5.23398V35.9898Z"
          />
        </g>
        <defs>
          <clipPath id="clip0_25_155">
            <rect width="127.026" height="40.4172" transform="translate(0.390137 0.566986)" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};
