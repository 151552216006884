import { createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const theme = createTheme({
  typography: {
    fontFamily: '"Heebo"',
  },
  status: {
    danger: orange[500],
  },
  palette: {
    primary: {
      main: '#a77a16',
    },
    secondary: {
      main: '#8A4BBB',
    },
    background: {
      default: '#a77a16',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
  },
});

export const buttonNavStyle = {
  textAlign: 'center',
  my: 2,
  mx: 0.4,
  px: 2,
  fontSize: '1.3em',
  fontWeight: 600,
  color: 'white',
  display: 'block',
  borderRadius: 0,
  letterSpacing: '0.135px',
  '&:before': {
    content: "''",
    position: 'absolute',
    width: '10%',
    height: '3px',
    bottom: '3px',
    left: '50%',
    transform: 'translate(-50%,0%)',
    backgroundColor: 'white',
    visibility: 'hidden',
    transition: 'all 0.2s ease-in-out',
  },
  '&:hover:before': {
    visibility: 'visible',
    width: '65%',
  },
  '&.active': {
    fontWeight: 700,
    letterSpacing: '0px',
  },
  '&.active:before': {
    content: "''",
    position: 'absolute',
    width: '65%',
    height: '5px',
    bottom: '3px',
    left: '50%',
    transform: 'translate(-50%,0%)',
    backgroundColor: 'white',
    visibility: 'visible',
  },
};

export default theme;
