import { Helmet } from 'react-helmet';
import { Box, Divider, Grid, Link, Stack, Typography } from '@mui/material';

import { usePrismicDocumentsByType, PrismicRichText } from '@prismicio/react';
import { format } from 'date-fns';

function Used() {
  const [documents] = usePrismicDocumentsByType('used_bikes', { pageSize: 100 });
  window.scrollTo(0, 0);
  return (
    <>
      <Helmet>
        <title>Thick Bikes - Used Bikes</title>
      </Helmet>
      <Stack
        alignItems="center"
        sx={{
          pt: ['50%', '24%'],
          pb: [2, 6],
          m: 0,
          px: 0,
          backgroundImage: "url('./static/used_image.jpg')",
          backgroundSize: 'cover',
        }}
      >
        <Stack direction="row" spacing={[2, 5, 5]}></Stack>
      </Stack>

      {documents && (
        <Stack sx={{ background: '#106B43', color: 'white' }}>
          <Typography sx={{ fontSize: [18, 22], lineHeight: 1.6, paddingTop: 6 }} paddingX={[4, 5]}>
            Listed below is a complete list of our professionally fixed-up used bicycles we have for
            sale. This gallery is kept very current - usually up to the minute. If a bike is shown
            here it should be available to purchase. So stop by and check out the bikes in person.
          </Typography>
          <Typography color="#ffd600" px={[4, 5]} pb={4} pt={2}>
            Last Updated:{' '}
            {format(
              new Date(
                documents.results.reduce((acc, curr) => {
                  if (acc < curr.last_publication_date) {
                    return curr.last_publication_date;
                  }
                  return acc;
                }, documents.results[0].last_publication_date) as any
              ),
              'MM/dd/yyyy - h:mma'
            )}
          </Typography>
          <Divider sx={{ height: '10px', background: '#FFA22B' }} color="white" />
          <Grid container spacing={[2, 4]} padding={[2, 4]} paddingBottom={12}>
            {documents.results.map((doc) => (
              <Grid key={doc.id} item md={6} xs={12} sm={6} lg={4}>
                <Link
                  sx={{ textDecoration: 'none' }}
                  href={doc.data.image.url as any}
                  target="_blank"
                  textAlign={'center'}
                  rel="noreferrer"
                >
                  <Box
                    width={'100%'}
                    borderRadius={4}
                    component="img"
                    src={doc.data.image.thumb.url}
                    alt=""
                    sx={{
                      border: '6px solid #106B43',
                      transition: 'border .2s ease-in',
                      '&:hover': {
                        border: '6px solid #FFA22B',
                        transition: 'border .1s ease-out',
                      },
                    }}
                  />
                  <Typography color={'white'} fontSize={'1.3em'}>
                    {doc.data?.description[0].text} /{' '}
                    <Typography component="span" color={'#ffd600'} fontSize={'1em'}>
                      {doc.data.price[0] ? '$' : ''}
                      {doc.data.price[0] ? doc.data?.price[0].text : ''}
                    </Typography>
                  </Typography>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Stack>
      )}
    </>
  );
}
export default Used;
