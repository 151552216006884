import { Routes, Route } from 'react-router-dom';
import New from '../views/New';
import About from '../views/About';
import Used from '../views/Used';
import Home from '../views/Home';
import Events from '../views/Events';
import Layout from '../components/Layout';
import Service from '../views/Service';

function Routing() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="service" element={<Service />} />
        <Route path="inventory" element={<New />} />
        <Route path="used" element={<Used />} />
        <Route path="events" element={<Events />} />
      </Route>
    </Routes>
  );
}

export default Routing;
