import { Helmet } from 'react-helmet';
import { Stack } from '@mui/material';
import { Container, Typography } from '@mui/material';

import { usePrismicDocumentsByType, PrismicRichText } from '@prismicio/react';
import { format } from 'date-fns';

function New() {
  const [documents] = usePrismicDocumentsByType('inventory');
  window.scrollTo(0, 0);

  return (
    <>
      <Helmet>
        <title>Thick Bikes - Current Inventory</title>
      </Helmet>
      <Stack
        alignItems="center"
        sx={{
          pt: ['50%', '24%'],
          pb: [2, 6],
          m: 0,
          px: 0,
          backgroundImage: "url('./static/inventory_image.jpg')",
          backgroundSize: 'cover',
        }}
      >
        <Stack direction="row" spacing={[2, 5, 5]}></Stack>
      </Stack>
      {documents && (
        <Stack sx={{ background: '#106B43', color: 'white' }} className="picked">
          <Container
            className="inventory"
            maxWidth="md"
            sx={{ px: 4, py: [5, 10], fontSize: [14, 16], lineHeight: '1.6em' }}
          >
            <>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 500,
                  textTransform: 'uppercase',
                  color: '#FFA22B',
                  fontSize: '.95em',
                }}
                pt={2}
              >
                Last Updated:{' '}
                {format(new Date(documents.results[0].last_publication_date), 'MM/dd/yyyy')}
              </Typography>
              <PrismicRichText field={documents.results[0].data.body} />
            </>
          </Container>
        </Stack>
      )}
    </>
  );
}
export default New;
