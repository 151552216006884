import { Helmet } from 'react-helmet';
import { Stack, Typography, Box, Container } from '@mui/material';

function About() {
  window.scrollTo(0, 0);

  return (
    <>
      <Helmet>
        <title>Thick Bikes - About</title>
      </Helmet>
      <Stack
        alignItems="center"
        sx={{
          pt: ['70%', '54%'],
          pb: [2, 6],
          m: 0,
          px: 0,
          backgroundImage: "url('./static/about_image.jpg')",
          backgroundSize: 'cover',
        }}
      >
        <Stack direction="row" spacing={[2, 5, 5]}></Stack>
      </Stack>

      <Stack sx={{ background: '#106B43', color: 'white' }}>
        <Container
          maxWidth="md"
          sx={{ px: 4, py: [5, 10, 20], fontSize: [14, 18], lineHeight: '1.35em' }}
        >
          <h1>It's a Bike Shop</h1>
          <Typography sx={{ fontSize: [18, 22], lineHeight: 1.6 }}>
            Thick Bikes is a Bike shop in Pittsburgh, PA. Started by Chris Beech in the year 2001.
            It started as a custom bike fabrication shop in the small garage. Right next to it's
            current location. Since Chris kept the garage door open when working, people walked in
            and ask him for help with their bikes. So he decided to add a repair stand. Then he
            started to sell bikes. Then hired some employees. Then stocked up with bike apparel.
            Over the past 20 years of slowly growing, Chris not only expanded past the garage, but
            into the entire building next door.
          </Typography>
        </Container>
      </Stack>
      <Stack
        alignItems="center"
        sx={{
          pt: ['50%'],
          pb: [6, 6],
          m: 0,
          px: 0,
          textShadow: '1px 1px 10px rgba(0,0,0,0.8)',
          backgroundImage: "url('./static/about2_image.jpg')",
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <Container
          sx={{
            color: 'white',
            textAlign: 'center',
            textTransform: 'uppercase',
          }}
        ></Container>
      </Stack>
      <Stack sx={{ background: '#8A4BBB', color: 'white' }}>
        <Container
          maxWidth="md"
          sx={{ px: 4, py: [5, 10, 20], fontSize: [14, 18], lineHeight: '1.35em' }}
        >
          <Typography sx={{ fontSize: [18, 22], lineHeight: 1.6 }}>
            Thick Bikes has been a major part of Pittsburgh's bike community for over 20 years.
            Sponsoring and raising a great deal of money for major events like the MS150. To
            creating small DIY events like Unicycles are a thing. Thick Bikes has also been
            recognized by Pittsburgh's City Paper for winning the most{' '}
            <a
              style={{ color: 'white' }}
              href="https://www.pghcitypaper.com/pittsburgh/best-of-pittsburgh-legacy-thick-bikes/BestOf?oid=9839513"
            >
              "Best Pittsburgh Bike Shop"
            </a>{' '}
            awards more than any other bike shop in the city.
          </Typography>
          <Typography sx={{ fontSize: [18, 22], lineHeight: 1.6, paddingTop: 6 }}>
            To this day you can catch Chris Beech at the shop with his rad son and his wonderful
            wife either working on bikes, helping customers, or out in the city riding bikes. For
            over 20 years Thick Bikes has been a bike shop that is run by people who love bikes and
            truly give a great deal to the growth of the Pittsburgh Bike Community.{' '}
          </Typography>
          <Typography sx={{ fontSize: [18, 22], lineHeight: 1.6, paddingTop: 6 }}>
            Stop by and say hi!
          </Typography>
        </Container>
      </Stack>
    </>
  );
}
export default About;
