import ReactDOM from 'react-dom';
import Routing from './components/Routing';
import { BrowserRouter } from 'react-router-dom';
import * as prismic from '@prismicio/client';
import { PrismicProvider } from '@prismicio/react';
import '@fontsource/heebo/900.css';
import '@fontsource/heebo/800.css';
import '@fontsource/heebo/600.css';
import '@fontsource/heebo/500.css';
import '@fontsource/heebo/400.css';
import '@fontsource/heebo/300.css';

const endpoint = prismic.getRepositoryEndpoint('thick');
const client = prismic.createClient(endpoint);

ReactDOM.render(
  <PrismicProvider client={client}>
    <BrowserRouter>
      <Routing />
    </BrowserRouter>
  </PrismicProvider>,
  document.getElementById('root')
);
