import { createRef, useState } from 'react';
import { Link as RouterLink, Outlet, NavLink, useLocation } from 'react-router-dom';
import { Box, Container, Toolbar, Typography, AppBar, Stack } from '@mui/material';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { buttonNavStyle } from '../styles/theme';
import theme from '../styles/theme';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import { ThickLogo } from './thickLogo';

const pages = [
  // { name: 'Service', url: 'service' },
  { name: 'Inventory', url: 'inventory' },
  { name: 'Used', url: 'used' },
  // { name: 'Events', url: 'events' },
  { name: 'About', url: 'about' },
];

function Layout() {
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event: { currentTarget: HTMLButtonElement }) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    window.scrollTo(0, 0);
    setAnchorElNav(null);
  };

  const location = useLocation();
  const onIndexPage = location.pathname === '/';
  const topRef = createRef<HTMLDivElement>();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar elevation={0} sx={{ background: 'none' }}>
        <Container sx={{ background: 'none' }}>
          <Toolbar disableGutters={true}>
            <Typography
              component={RouterLink}
              to={'/'}
              sx={{ mr: 2, cursor: 'pointer', display: { xs: 'none', sm: 'flex' } }}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onClick={(e: any) => {
                if (onIndexPage) {
                  e.preventDefault();
                  topRef.current.scrollIntoView({ behavior: 'smooth' });
                } else {
                  window.scrollTo(0, 0);
                }
              }}
            >
              {ThickLogo()}
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', sm: 'none' },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', sm: 'none' },
                }}
              >
                {pages.map(({ name, url }) => (
                  <MenuItem component={RouterLink} key={url} to={url} onClick={handleCloseNavMenu}>
                    <Typography>{name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              to={'/'}
              sx={{ pr: '3.2em', pt: '.6em', flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}
              component={RouterLink}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onClick={(e: any) => {
                if (onIndexPage) {
                  e.preventDefault();
                  topRef.current.scrollIntoView({ behavior: 'smooth' });
                } else {
                  window.scrollTo(0, 0);
                }
              }}
            >
              {ThickLogo()}
            </Typography>
            <Box justifyContent="end" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}>
              {pages.map(({ name, url }) => (
                <Button
                  component={NavLink}
                  to={url}
                  key={url}
                  onClick={handleCloseNavMenu}
                  sx={buttonNavStyle}
                >
                  {name}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Container disableGutters ref={topRef}>
        <Outlet />
        <Stack
          alignItems="center"
          className="picked"
          sx={{
            pt: ['20%', '14%'],
            pb: [6, 6],
            m: 0,
            px: 0,
            textShadow: '1px 1px 10px rgba(0,0,0,0.8)',
            backgroundImage: "url('./static/footer_image.jpg')",
            backgroundSize: 'cover',
          }}
        >
          <Container
            sx={{
              color: 'white',
              textAlign: 'center',
              pt: 40,
              textTransform: 'uppercase',
            }}
          >
            <Box bgcolor={'#000'} py={2}>
              <Typography fontSize={20}>
                Established in 2001 - <a href="https://www.facebook.com/thickbikes/"> facebook</a> /{' '}
                <a href="https://twitter.com/thickbikes">twitter</a> /{' '}
                <a href="https://www.instagram.com/thickbikes/">instagram</a>
              </Typography>
            </Box>
          </Container>
        </Stack>
      </Container>
    </ThemeProvider>
  );
}

export default Layout;
