import { Container, Typography, Button, Stack, Box, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { usePrismicDocumentsByType, PrismicRichText } from '@prismicio/react';
import { createRef } from 'react';
import { Helmet } from 'react-helmet';
import { format } from 'date-fns';
import theme from '../styles/theme';
import './home.css';
import { Link } from 'react-router-dom';

function Home() {
  const hoursRef = createRef<HTMLDivElement>();
  const locationsRef = createRef<HTMLDivElement>();
  const contactRef = createRef<HTMLDivElement>();
  const [documents] = usePrismicDocumentsByType('home_page');

  const HoursContainer: React.FC = ({ children }) => {
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {children}
      </Stack>
    );
  };

  const Divider: React.FC = () => (
    <Box mr={[4]} sx={{ borderBottom: '4px solid #FFA22B', width: '60%', height: '1px' }} />
  );

  const DayLabelTypography: React.FC = ({ children }) => {
    return (
      <Typography fontSize={[18, 22]} width="7em" my={[2]}>
        {children}
      </Typography>
    );
  };

  const DayInfoTypography: React.FC = ({ children }) => {
    return (
      <Typography
        fontSize={[18, 22]}
        width="7em"
        my={[2]}
        sx={{ whiteSpace: 'nowrap' }}
        fontWeight={500}
      >
        {children}
      </Typography>
    );
  };

  return (
    <>
      <Helmet>
        <title>Thick Bikes - Pittsburgh Bike Shop</title>
      </Helmet>

      <Stack
        alignItems="center"
        sx={{
          pt: ['20%', '14%'],
          pb: [6, 6],
          m: 0,
          px: 0,
          textShadow: '1px 1px 10px rgba(0,0,0,0.8)',
          backgroundImage: "url('./static/main_image.jpg')",
          backgroundSize: 'cover',
        }}
      >
        <Container
          sx={{
            color: 'white',
            textAlign: 'center',
            py: 10,
            textTransform: 'uppercase',
          }}
        >
          <Typography fontSize={[26, 25, 40]} sx={{ textShadow: '1px 1px 2px rgba(0,0,0,0.8)' }}>
            welcome to
          </Typography>
          <Typography
            fontWeight={900}
            my={[2, 1]}
            lineHeight={1.2}
            fontSize={[90, 80, 128]}
            letterSpacing={6}
            color={'#ffd600'}
          >
            Thick Bikes
          </Typography>
          <Typography fontSize={[32, 25, 40]} sx={{ textShadow: '1px 1px 2px rgba(0,0,0,0.8)' }}>
            Pittsburgh's <span style={{ fontWeight: 800 }}>Bikey'est</span> Bike Shop.
          </Typography>
        </Container>
        <Stack direction="row" spacing={[2, 5, 5]}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ fontSize: [12, 18], px: [3, 5] }}
            size="large"
            onClick={() => hoursRef.current.scrollIntoView({ behavior: 'smooth' })}
          >
            Hours
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{ fontSize: [12, 18], px: [3, 5] }}
            size="large"
            onClick={() => locationsRef.current.scrollIntoView({ behavior: 'smooth' })}
          >
            Location
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{ fontSize: [12, 18], px: [3, 5] }}
            size="large"
            onClick={() => contactRef.current.scrollIntoView({ behavior: 'smooth' })}
          >
            Contact
          </Button>
        </Stack>
      </Stack>

      <Container
        sx={{
          backgroundColor: '#106B43',
          color: 'white',
          py: 12,
          borderTop: '10px solid #FFA22B',
        }}
      >
        <Typography className="picked" sx={{ fontSize: [18, 22], px: [3, 5], lineHeight: 1.6 }}>
          If you're looking for a unique bike shop in Pittsburgh that carries the best brands in the
          industry, a full on machine shop that can repair any bike issue, and top tier vibes...
          Then you've come to the right place!
        </Typography>
        <Typography
          className="picked"
          sx={{ fontSize: [18, 22], px: [3, 5], pt: 6, lineHeight: 1.6 }}
        >
          We started our bike shop on the Southside of Pittsburgh over 20 years ago. Since then
          we've helped thousands of people find the perfect bike for their needs. We're a full
          service bike shop that can help you with any bike related issue you may have. We also have
          a great selection of used bikes for sale. Check out our
          <Link to="/used">Used Bikes</Link> section. Or interested in a new bike, visit our{' '}
          <Link to="/inventory">Inventory</Link> for a full up to date list of our bike selection.
        </Typography>
        <Typography
          className="picked"
          sx={{ fontSize: [18, 22], px: [3, 5], pt: 6, lineHeight: 1.6 }}
        >
          Either way, glad you stopped by. Keep being weird and bikey.
        </Typography>
      </Container>

      <Container
        disableGutters
        sx={{
          pt: ['70%', '50%'],
          pb: [4, 6],
          m: 0,
          px: 0,
          backgroundImage: "url('./static/second_home_image.jpg')",
          backgroundSize: 'cover',
        }}
      >
        <Stack
          direction="column"
          alignItems="center"
          sx={{ background: theme.palette.secondary.main }}
        >
          <Typography
            fontSize={[16, 18, 22]}
            p={[2, 4]}
            sx={{ color: 'white', textTransform: 'uppercase', py: 1, fontWeight: 600 }}
          >
            Just a perfectly normal bike SHOP in the Southside of Pittsburgh
          </Typography>
        </Stack>
      </Container>

      <Container
        ref={locationsRef}
        disableGutters
        sx={{
          m: 0,
          pt: 26,
          pb: 32,
          px: 0,
          pl: '12%',
          maxHeight: '800px',
          backgroundImage: "url('./static/third_home_image.jpg')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Stack alignItems="flex-start">
          <Card sx={{ maxWidth: 325, borderRadius: '12px' }}>
            <CardMedia
              component="img"
              height="140"
              image="/static/parkinglot.jpg"
              alt="green iguana"
            />
            <CardContent sx={{ p: 2 }}>
              <Typography
                variant="h5"
                component="div"
                sx={{ fontSize: '1.8em', fontWeight: '600', color: '#8a4bbb' }}
              >
                62 S. 15th Street
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                Pittsburgh, Pa - 15203
              </Typography>
              <Typography variant="body2">
                We have a parking lot in front of our building in case you're worried about parking.
              </Typography>
            </CardContent>
            <CardActions sx={{ px: 2, pb: 2 }}>
              <Button
                variant="outlined"
                href="https://www.google.com/maps?ll=40.42614,-79.978377&z=16&t=m&hl=en&gl=US&mapclient=embed&cid=10115945386035297245"
                sx={{
                  color: '#fff',
                  background: '#8a4bbb',
                  width: '100%',
                  [':hover']: { background: '#fff', color: '#8a4bbb', border: '1px solid #8a4bbb' },
                }}
              >
                Google Maps
              </Button>
            </CardActions>
          </Card>
        </Stack>
      </Container>

      {documents && (
        <Stack sx={{ background: '#106B43', color: 'white' }}>
          <Container
            className="news"
            maxWidth="md"
            sx={{ px: 4, py: [5, 10, 20], fontSize: [14, 18], lineHeight: '1.35em' }}
          >
            <>
              <h1>Shop updates</h1>

              <Typography variant="h6" sx={{ fontWeight: 500 }} pt={2}>
                {format(new Date(documents.results[0].last_publication_date), 'MM/dd/yyyy')}
              </Typography>
              <Box sx={{ fontSize: '1.4em', lineHeight: '1.4em' }}>
                <PrismicRichText field={documents.results[0].data.body} />
              </Box>
            </>
          </Container>
        </Stack>
      )}

      <Stack sx={{ background: '#8A4BBB', color: 'white' }}>
        <Container
          maxWidth="md"
          ref={hoursRef}
          sx={{ px: 4, py: [5, 10, 20], fontSize: [14, 18], lineHeight: '1.35em' }}
        >
          <h1>When we're open</h1>

          <HoursContainer>
            <DayLabelTypography>Mon </DayLabelTypography>
            <Divider />
            <DayInfoTypography>Closed</DayInfoTypography>
          </HoursContainer>
          <HoursContainer>
            <DayLabelTypography>Tues </DayLabelTypography>
            <Divider />
            <DayInfoTypography>11a - 7p</DayInfoTypography>
          </HoursContainer>
          <HoursContainer>
            <DayLabelTypography>Wed </DayLabelTypography>
            <Divider />
            <DayInfoTypography>11a - 7p</DayInfoTypography>
          </HoursContainer>
          <HoursContainer>
            <DayLabelTypography>Thurs </DayLabelTypography>
            <Divider />
            <DayInfoTypography>8a - 4p</DayInfoTypography>
          </HoursContainer>
          <HoursContainer>
            <DayLabelTypography>Fri </DayLabelTypography>
            <Divider />
            <DayInfoTypography>11a - 7p</DayInfoTypography>
          </HoursContainer>
          <HoursContainer>
            <DayLabelTypography>Sat </DayLabelTypography>
            <Divider />
            <DayInfoTypography>9a - 5p</DayInfoTypography>
          </HoursContainer>
          <HoursContainer>
            <DayLabelTypography>Sun </DayLabelTypography>
            <Divider />
            <DayInfoTypography>Closed</DayInfoTypography>
          </HoursContainer>
        </Container>
      </Stack>

      <Stack
        alignItems="center"
        sx={{
          pt: ['20%', '14%'],
          pb: [6, 6],
          m: 0,
          px: 0,
          textShadow: '1px 1px 10px rgba(0,0,0,0.8)',
          backgroundImage: "url('./static/center_image.jpg')",
          backgroundSize: 'cover',
        }}
      >
        <Container
          sx={{
            color: 'white',
            textAlign: 'center',
            py: 25,
            textTransform: 'uppercase',
          }}
        ></Container>
      </Stack>

      <Stack sx={{ background: '#106B43', color: 'white' }}>
        <Container
          className="news"
          maxWidth="md"
          ref={contactRef}
          sx={{ px: 4, py: [5, 10, 20], fontSize: [14, 18], lineHeight: '1.35em' }}
        >
          <h1>How to contact us</h1>
          <p style={{ fontSize: '1.4em', lineHeight: '1.4em' }}>
            Feel free to contact us about anything bike related. We're friendly.
          </p>

          <Grid container sx={{ borderBottom: '1px solid white' }} fontSize={20}>
            <Grid item sm={12} py={2} mb={2}>
              Call our Shop: <a href="tel:4123903590">(412) 390-3590</a>
            </Grid>
          </Grid>

          <Grid container py={2} fontSize={20}>
            <Grid item xs={12} sm={6}>
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" pb={1} sx={{ fontWeight: 500 }}>
                  Technical Questions
                </Typography>
                <a href="mailto:tech@thickbikes.com">tech@thickbikes.com</a>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" pb={1} sx={{ fontWeight: 500 }}>
                  Sales Questions{' '}
                </Typography>
                <a href="mailto:sales@thickbikes.com">sales@thickbikes.com </a>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Stack>

      <Stack sx={{ background: '#8A4BBB', color: 'white' }}>
        <Container
          maxWidth="md"
          className="picked"
          sx={{ px: 4, py: [5, 10, 20], fontSize: [14, 18], lineHeight: '1.35em' }}
        >
          <div style={{ fontSize: '1.4em', lineHeight: '1.4em' }}>
            <h1>Who we carry</h1>
            <p>
              We carry a wide range of quality brands including <span>bikes</span> from{' '}
              <a href="https://www.specialized.com/us/en" target="_blank">
                Specialized
              </a>
              ,{' '}
              <a href="https://www.giant-bicycles.com/us" target="_blank">
                Giant
              </a>
              ,{' '}
              <a href="https://salsacycles.com/" target="_blank">
                Salsa
              </a>
              ,{' '}
              <a href="https://surlybikes.com/" target="_blank">
                Surly
              </a>{' '}
              and{' '}
              <a href="https://allcitycycles.com/" target="_blank">
                All-City
              </a>
              .
            </p>
            <p>
              We have <span>eBikes</span> from{' '}
              <a href="https://www.gazellebikes.com/" target="_blank">
                Gazelle
              </a>
              ,{' '}
              <a href="https://www.giant-bicycles.com/us/ebikes-overview" target="_blank">
                Giant
              </a>{' '}
              and{' '}
              <a href="https://www.specialized.com/us/en/electric-bikes" target="_blank">
                Specialized
              </a>{' '}
              as well.
            </p>
            <p>
              You will also find a selection of <span>eCargo bikes</span> from{' '}
              <a href="https://www.urbanarrow.com/en" target="_blank">
                Urban Arrow
              </a>
              ,{' '}
              <a href="https://yubabikes.com/" target="_blank">
                Yuba
              </a>
              ,{' '}
              <a href="http://www.larryvsharry.com/" target="_blank">
                Larry Vs. Harry
              </a>
              , and{' '}
              <a href="https://surlybikes.com/bikes/big_easy" target="_blank">
                Surly
              </a>
              .
            </p>
            <p>
              We also fix up and sell <span>used bikes</span>. Visit our{' '}
              <a href="/used">Used Bike Photo Album</a> to see our current listing of used bikes.
            </p>
          </div>
        </Container>
      </Stack>
    </>
  );
}
export default Home;
